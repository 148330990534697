import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import WrappingList from "../components/buy-equipment/wrapping-list"

import styles from "./buy-equipment.module.css"

class BuyEquipment extends Component {
  render() {
    const { data } = this.props;

    const labEquipCategoryNodes = data.allCategory.nodes.filter(category => {
      return category.parentCategorySlug == `/lab-equipment`
    });

    const packagingEquipCategoryNodes = data.allCategory.nodes.filter(category => {
      return category.parentCategorySlug == `/packaging-equipment`
    });

    const processingEquipCategoryNodes = data.allCategory.nodes.filter(category => {
      return category.parentCategorySlug == `/processing-equipment`
    });

    return (
      <Layout>
        <SEO
          title={`Used Pharmaceutical Equipment & Biotech Instruments For Sale`}
          description={`Surplus Solutions carries a wide variety of used pharmaceutical lab, packaging, and processing equipment. Contact us today for more information on how we can help solve your equipment needs.`}
          />
        <div className="row columns">
          <h4 className="headings mt2">Lab Equipment</h4>
          <WrappingList containerClassName={styles.container} data={labEquipCategoryNodes} slugPrefix={"/catalog"}/>
        </div>

        <div className="row columns mt10">
          <h4 className="headings mt2">Packaging Equipment</h4>
          <WrappingList containerClassName={styles.container} data={packagingEquipCategoryNodes} slugPrefix={"/catalog"} />
        </div>

        <div className="row columns mt10">
          <h4 className="headings mt2">Processing Equipment</h4>
          <WrappingList containerClassName={styles.container} data={processingEquipCategoryNodes} slugPrefix={"/catalog"} />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
query {
  allCategory(sort: { fields: [name], order: ASC }) {
    nodes {
      parentCategorySlug
      slug
      name
      productCount
    }
  }
}
`

export default BuyEquipment
